import { SignOutUser } from "../services/auth.js";
import css from "./navBarCSS.js";
class navBar extends HTMLElement {
  constructor() {
    super();
    this.root = this.attachShadow({ mode: "open" });
    const styles = document.createElement("style");
    this.root.appendChild(styles);
    async function loadCSS() {
      // const request = await fetch("../../src/components/navBar.css");
      // const request = await fetch("./navBar.css");
      // styles.textContent = await request.text();
      styles.textContent = css;
    }
    loadCSS();
    this.userData = JSON.parse(localStorage.getItem("user"));
  }

  connectedCallback() {
    window.addEventListener("newProjectSetting", () => {
      this.showSettingsBUtton();
    });
    this.render();
    this.addListeners();
    this.showSettingsBUtton();
  }

  render() {
    const template = document.createElement("header");
    template.innerHTML = `
    <nav>
      <a href="/qr-codes">Dynamic Qr-Code</a>
      <div>
      <button id="projectSettings">
      ${
        this.userData
          ? `<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
        <path
          d="m370-80-16-128q-13-5-24.5-12T307-235l-119 50L78-375l103-78q-1-7-1-13.5v-27q0-6.5 1-13.5L78-585l110-190 119 50q11-8 23-15t24-12l16-128h220l16 128q13 5 24.5 12t22.5 15l119-50 110 190-103 78q1 7 1 13.5v27q0 6.5-2 13.5l103 78-110 190-118-50q-11 8-23 15t-24 12L590-80H370Zm70-80h79l14-106q31-8 57.5-23.5T639-327l99 41 39-68-86-65q5-14 7-29.5t2-31.5q0-16-2-31.5t-7-29.5l86-65-39-68-99 42q-22-23-48.5-38.5T533-694l-13-106h-79l-14 106q-31 8-57.5 23.5T321-633l-99-41-39 68 86 64q-5 15-7 30t-2 32q0 16 2 31t7 30l-86 65 39 68 99-42q22 23 48.5 38.5T427-266l13 106Zm42-180q58 0 99-41t41-99q0-58-41-99t-99-41q-59 0-99.5 41T342-480q0 58 40.5 99t99.5 41Zm-2-140Z" />
      </svg>`
          : ""
      }
      </button>
      <button id="userImageButton">
      ${
        this.userData.photoURL != null
          ? `<img src="${this.userData.photoURL}">`
          : "<img src='https://firebasestorage.googleapis.com/v0/b/qr-ohooo.appspot.com/o/profileImages%2Fuser-icon-default.png?alt=media&token=1b54e5c0-cece-4289-97b8-b27803603245' alt='user'>"
      }
    </button>

    </div>
    </nav>
    `;
    this.root.appendChild(template);
  }

  addListeners() {
    const imageButton = this.root.getElementById("userImageButton");
    document.getElementById("userImage").src = this.userData.photoURL;

    imageButton.onclick = (event) => {
      const dialog = document.getElementById("userModal");
      dialog.setAttribute("open", true);
    };

    const dialog = document.getElementById("userModal");
    const closeButton = document.getElementById("closeDialog");
    closeButton.onclick = (event) => {
      dialog.removeAttribute("open");
    };
    const signOutButton = document.getElementById("signOutButton");
    signOutButton.onclick = (event) => {
      SignOutUser();
      dialog.removeAttribute("open");
    };
    const profileButton = document.getElementById("profileButton");
    profileButton.onclick = () => {
      const routes = {
        route: "/profile",
        component: "profile-page",
      };
      dialog.removeAttribute("open");
      app.router.go(routes);
    };
    const settingsButton = this.root.getElementById("projectSettings");
    settingsButton.onclick = (event) => {
      const routes = {
        route: "/projectsettings",
        component: "projectsetting-page",
      };
      app.router.go(routes);
    };
  }

  showSettingsBUtton() {
    try {
      this.projectID = JSON.parse(localStorage.getItem("selectedProject"));
    } catch (error) {}
    const settingsButton = this.root.getElementById("projectSettings");
    try {
      if (this.projectID.length < 2) {
        settingsButton.style.display = "none";
      } else {
        settingsButton.style.display = "block";
      }
    } catch (error) {
      settingsButton.style.display = "none";
    }
  }
}
customElements.define("navigation-top", navBar);
