
    const css = `* {
  font-family: header;
}
h1 {
  color: red;
}
header {
  width: 100%;
  height: 60px;
  margin: 0 0 30px 0;
  background-color: #a2ded0;
}
nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}
div {
  display: flex;
  align-items: center;
  height: 60px;
}
img {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  object-fit: cover;
}
#userImageButton {
}
#projectSettings {
  display: flex;
  align-items: center;
}
svg {
  transform: scale(1.4);
}
button {
  background-color: transparent;
  border: none;
  height: 90%;
  margin: 0 10px;
}
a {
  text-decoration: none;
  color: black;
  font-size: 20px;
  margin: 0 10px;
}`;
    export default css;
  